<template>
  <div class="container-warp">
    <div class="top-box">
      <div>
        <div class="return-title">
          <div class="back-btn" @click="_backBtn"><i class="el-icon-back mr10"></i>返回</div>
          <span class="division">|</span>
          <div class="back-title">课程详情</div>
        </div>
        <div class="name-box">
          <com-title>{{courseIntroduceInfo.name}}</com-title>
          <div>
          </div>
          <div class="name-box-r">
            <span>课程编号 {{courseIntroduceInfo.courseCode||'—'}}</span>
            <span class="line-box"></span>
            <span>课程时长 共{{courseIntroduceInfo.sectionNum}}节 <span v-if="currentLeftContent?.fileType==='video'">| {{courseIntroduceInfo.fileDuration}}</span></span>
          </div>
        </div>
      </div>
      <!--课程目录学习---开始 -->
      <div class="video-box">
        <div class="video-box-l mytable-scrollbar" ref="scrollPdf">
          <!-- 视频格式--开始 -->
          <div v-if="currentLeftContent?.fileType==='video'">
            <vedioPlay
              style="height:100%;width:100%;"
              ref="vedioPlay"
              :key="refreshVedio"
              :currentLeftContent="currentLeftContent"
              :src="currentLeftContent?.sourceFileUrl"
              @updateVideoPace="_updateVideoPace"
              @updateVideoPaceOld="_updateVideoPaceOld">
            </vedioPlay>
          </div>
          <!-- 视频格式--结束 -->
          <!-- pdf格式--开始 -->
          <!-- <div v-else-if="currentLeftContent?.fileType==='file'" >
            <viewPDFFile :src="currentLeftContent?.sourceFileUrl" ref="viewPdfRef" style="width:100%">
            </viewPDFFile>
          </div> -->
          <!-- 文件格式--开始 -->
          <div v-else-if="currentLeftContent?.fileType==='file'">
            <iframe
              id="inlineFrameExample"
              allow="fullscreen"
              width="99%"
              height="600"
              :src="currentUrl">
            </iframe>
          </div>
          <!--文件格式--结束  -->
          <!-- 图片格式---开始 -->
          <div v-else-if="currentLeftContent?.fileType==='image'" style="width:100%;text-align:center;">
            <img style="max-height: 610px;" :src="currentLeftContent?.sourceFileUrl"/>
          </div>
          <div v-else style="max-height: 610px;">
            <div class="empty-box">
              <PlaceholderMap text="暂无数据~" />
            </div>
          </div>
          <!-- 图片格式--结束 -->
        </div>
        <div class="video-box-r mytable-scrollbar">
          <div class="catalogue-box">
            <img src="@/assets/img/icon_catalogue.png" alt="" />
            <span>目录</span>
          </div>
          <div v-for="(item,index) in courseDirectoryData" :key="item.id" :class="currentActiveCourse==item.id?'course-active':''">
            <div class="catalogue-box-item" @click="_selectCourseDirBtn(item,index)">
              <div class="catalogue-box-item-l">
                <i v-if="item?.fileType==='video'" class="iconfont icon-icon_course"></i>
                <i v-else class="iconfont icon-icon_course_name"></i>
                {{item.sectionName}}
              </div>
              <div class="catalogue-box-item-r" v-if="item?.fileType==='video'">{{_convertTimeToHMS(Number(item.sectionDuration)*1000)}}</div>
              <div class="catalogue-box-item-r" v-else-if="item?.fileType==='file'">{{item.sourceFileUrl.substring(item.sourceFileUrl.lastIndexOf(".")+1)}}</div>
              <div class="catalogue-box-item-r" v-else>{{item?.fileType}}</div>
            </div>
          </div>
        </div>
      </div>
      <!--课程目录学习---结束 -->
    </div>
    <div class="bottom-box" style="margin-top: 16px">
      <el-tabs v-model="activeName" :style="{'--font-Color' : fontColor}">
          <el-tab-pane label="课程介绍" name="0">
            <div style="margin-bottom:24px;">
              <com-title>课程描述</com-title>
              <div class="describe-text">
                {{courseIntroduceInfo.description||'—'}}
              </div>
            </div>
            <div>
            <com-title>课程介绍内容</com-title>
            <div class="describe-text">
              <div v-if="courseIntroduceInfo.introduce">
                <span v-html="courseIntroduceInfo.introduce"></span>
              </div>
              <div v-else>
                —
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="课程资料" name="1">
          <div class="content">
            <div class="empty-box" v-if="courseMaterialData.length === 0">
              <PlaceholderMap text="暂无课程资料噢~" />
            </div>
            <div class="item-content" v-for="(item,index) in courseMaterialData" :key="index">
              <div class="item-left">
                <img src="@/assets/img/text.png" alt="" />
                <div class="hover-name">{{item.fileName}}</div>
              </div>
              <div class="item-right">
                <el-button type="text" @click="downloadFile(item)">下载</el-button>
                <el-button type="text" @click="_previewFileHandle(item,index)">查看</el-button>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 列表预览--开始 -->
    <PreviewFile
      v-if="previewFileShow"
      :previewFileShow="previewFileShow"
      :currentFile="currentFile"
      ref="previewFile"
      ></PreviewFile>
      <!-- 列表预览--结束 -->
    <!-- PDF预览 -->
    <ViewPDFFile
      v-if="isViewPDFFile"
      :isViewPDFFileShow="isViewPDFFile"
      ref="viewPDFFile"
      :src="currentFileUrl"
      @closePopup="closePopup"
      style="width:100%">
      </ViewPDFFile>
  </div>
</template>
<!-- <script type="text/javascript" src="https://cdn.jsdelivr.net/npm/js-base64@3.6.0/base64.min.js"></script> -->
<script>
import { getStudyArrangeCourseDetailApi, getUpdateStudyPaceApi } from '@/api/study'
// import backTitle from '@/components/backTitle/index.vue'
import comTitle from '@/components/comTitle/index.vue'
import vedioPlay from '@/components/vedioPlay/index.vue'
import viewPDFFile from '@/components/viewPDFFile/index.vue'
import PlaceholderMap from '@/components/placeholderMap/index.vue'
import PreviewFile from '@/components/previewFile/index.vue'
import ViewPDFFile from '@/components/viewPDFFile/indexDialog'
import fileType from '@/mixins/fileType'
export default {
  mixins: [fileType],
  components: {
    // backTitle,
    comTitle,
    vedioPlay,
    viewPDFFile,
    PlaceholderMap,
    PreviewFile,
    ViewPDFFile
  },
  data () {
    return {
      fontColor: this.$store.state.settings.theme,
      activeName: '0',
      refreshVedio: new Date().getTime(),
      courseIntroduceInfo: {}, // 课程介绍
      courseDirectoryData: [], // 课程目录
      courseMaterialData: [], // 课程资料
      currentActiveCourse: 0, // 当前激活的目录
      currentLeftContent: {}, // 当前左侧内容信息
      previewFileShow: false,
      isViewPDFFile: false,
      currentFileUrl: '',
      leaveCurrentPage: true,
      oldVideoInfo: {},
      newVideoInfo: {},
      currentUrl: '', // 当前url
      currentXLSXUrl: '',
      currentPPTXUrl: ''
    }
  },
  // computed: {
  //   scrollPdf () {
  //     return this.$refs.scrollPdf
  //   }
  // },
  watch: {
    'currentLeftContent' (newContentValue, oldContentValue) {
      this.newVideoInfo = newContentValue
      this.oldVideoInfo = oldContentValue
    }
  },
  created () {
    this.fontColor = this.$store.state.settings.theme
    this.refreshVedio = new Date().getTime()
    if (this.$route.query.courseId) {
      this._getOnlineCourseDetail(this.$route.query.courseId, 'init')
    }
  },
  // vue路由的钩子函数 , 可以放在mouted的同级
  beforeRouteLeave (to, from, next) {
    if (this.leaveCurrentPage) { // 未离开当前页面
      next()
    } else { // 如果是当前浏览的是视频 离开当前页面-暂停视频，并保存视频进度，其他不需处理
      if (this.$refs.vedioPlay && this.$refs.vedioPlay.isPlay) {
        this.$refs.vedioPlay.play()// 暂停视频
        // console.log('beforeRouteLeave-----------')
        this._updateVideoPace(this.$refs.vedioPlay.video.currentTime)
      }
      next()
    }
  },
  mounted () {
    document.addEventListener('visibilitychange', this.handleVisiable, false)
  },
  // 页面销毁之前
  beforeDestory () {
    document.removeEventListener('visibilitychange', this.handleVisiable, false)
  },
  // 页面销毁
  destroyed () {
    document.removeEventListener('visibilitychange', this.handleVisiable, false)
  },
  methods: {
    closePopup () {
      this.isViewPDFFile = false
    },
    // 获取详情
    _getOnlineCourseDetail (courseId, type) {
      getStudyArrangeCourseDetailApi({ courseId: courseId }).then((res) => {
        if (res.code === 200) {
          this.courseIntroduceInfo = JSON.parse(JSON.stringify(res.data.courseIntroduction))
          const tempFileDuration = Number(this.courseIntroduceInfo.fileDuration) * 1000
          this.courseIntroduceInfo.fileDuration = this._convertTimeToHMS(tempFileDuration)
          this.courseDirectoryData = JSON.parse(JSON.stringify(res.data.courseFileResps))
          if (type === 'init') {
            // 初始化 如果该课程未学习过，就需要默认为第一条，否则默认为之前已打卡的目录
            const tempCurrentLeftContent = this.courseDirectoryData.filter((item) => item.learningMarker === 1)[0]
            if (tempCurrentLeftContent) {
              this.currentLeftContent = tempCurrentLeftContent
            } else {
              this.currentLeftContent = this.courseDirectoryData[0]
            }
            this.currentActiveCourse = this.currentLeftContent?.id
          }
          this.currentUrl = this.getPreviewUrl(this.currentLeftContent)// 处理文件预览格式
          this.courseMaterialData = res.data.courseMaterials
        }
      })
    },
    // 选择右侧课程目录
    _selectCourseDirBtn (item, index) {
      this._getOnlineCourseDetail(this.$route.query.courseId)
      this.refreshVedio = new Date().getTime()
      if (item.fileType === 'file') {
        this.currentUrl = this.getPreviewUrl(item)// 处理文件预览格式
      }
      this.currentActiveCourse = item.id
      this.currentLeftContent = item
    },
    // 查看课程资料
    _previewFileHandle (row, index) {
      // 列表预览
      if (row.fileType === 'image' || row.fileType === 'video') {
        this.previewFileShow = true
        this.currentFile = row
      } else {
        const previewUrl = this.getPreviewUrl(row)// 处理文件预览格式
        window.open(previewUrl)
      }
    },
    // 删除课程资料吗，=
    _deleteTrainee (item, index) {
      // 删除
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

      })
    },
    // 更新视频进度
    _updateVideoPace (currentTime, currentFileInfo) {
      const params = {
        scheduleId: this.$route.query.courseId, // 课程进度id
        sectionId: currentFileInfo ? currentFileInfo.sectionId : this.currentLeftContent.sectionId, // 小节id
        markLabel: currentTime, // 有效学习时长
        learningDuration: currentTime, // 学习时长
        id: currentFileInfo ? currentFileInfo.id : this.currentLeftContent.id,
        fileType: currentFileInfo ? 'video' : this.currentLeftContent.fileType
      }
      getUpdateStudyPaceApi(params).then((res) => {
        if (res.code === 200) {
        }
      })
    },
    _updateVideoPaceOld (currentTimeOld) {
      console.log(this.$route.name, '视频实例销毁了')
      if (this.$route.name === 'myOnlineCourseDetails') {
        // 当old为视频时,--暂停视频，并更新视频进度，如果old不是视频不需要处理
        if (this.oldVideoInfo.fileType === 'video') {
        // 当old为视频时，如果正在播放，需要暂停并更新视频进度
          // console.log('old1-----------')
          this._updateVideoPace(currentTimeOld, this.oldVideoInfo)
        }
        // 此时更新的是视频，再次调用此接口，视频初始化进度时间为之前所浏览的位置
        if (this.newVideoInfo.fileType === 'video') {
          if (this.$refs.vedioPlay) {
            // console.log('old2-----------')
            this._updateVideoPace(this.newVideoInfo.markLabel, this.newVideoInfo)
          }
        } else {
        // 此时更新的不是视频，再次调用此接口，视频进度时间为null
          // console.log('old3-----------')
          this._updateVideoPace(null, this.newVideoInfo)
        }
      }
    },
    // 将毫秒转成时分秒
    _convertTimeToHMS  (tempTime) {
      let formatTime = ''
      const hours = parseInt((tempTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = parseInt((tempTime % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = parseInt((tempTime % (1000 * 60)) / 1000)
      const tempHours = hours > 9 ? hours : '0' + hours
      const tempMin = minutes > 9 ? minutes : '0' + minutes
      const tempSec = seconds > 9 ? seconds : '0' + seconds
      if (tempHours != 0) {
        formatTime = (`${tempHours}时${tempMin}分${tempSec}秒`).slice(0, 9)
      } else if (tempMin != 0) {
        formatTime = (`${tempMin}分${tempSec}秒`).slice(0, 6)
      } else {
        formatTime = (`${tempSec}秒`).slice(0, 3)
      }
      return formatTime
    },
    // 监听课程观看进度---页面切换保存视频进度
    handleVisiable (e) {
      // console.log('here1111-------------')
      e = e || window.event
      e.returnValue = '离开此页面？'
      switch (e.target.visibilityState) {
        case 'prerender':
          break
        case 'hidden':
          if (this.$refs.vedioPlay && this.$refs.vedioPlay.isPlay) {
            this.$refs.vedioPlay.play()// 暂停视频
            // console.log('内容不可见，处理后台、最小化、锁屏状态')
            // console.log('handleVisiable-----------')
            this._updateVideoPace(this.$refs.vedioPlay.video.currentTime, this.currentLeftContent)
          }
          break
        case 'visible':
          // console.log('处于正常打开')
          break
      }
    },
    _backBtn () {
      this.$router.push({
        path: '/myStudyPlanDetail',
        query: {
          studyPlanId: this.$route.query.studyPlanId
        }
      })
    },
    handleScroll () {
      // 获取dom滚动距离
      const scrollTop = this.scrollPdf.scrollTop
      // 获取可视区高度
      // const offsetHeight = this.scrollPdf.offsetHeight
      // // 获取滚动条总高度
      // const scrollHeight = this.scrollPdf.scrollHeight
      // const scrollBottom = this.scrollPdf.scrollBottom
      if (this.currentLeftContent?.fileType === 'file') {
        // console.log(scrollTop,offsetHeight,scrollHeight,scrollBottom,'scrollTop')
        if (scrollTop > 50) {
          this.$refs.viewPdfRef.changePdfPage(1)// 下一页
          this.scrollPdf.scrollTop = 1
        } else if (scrollTop < 1) {
          this.$refs.viewPdfRef.changePdfPage(0)// 上一页
          this.scrollPdf.scrollTop = 1
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/iconfont/iconfont.css";
/deep/.el-tabs__item.is-active{
    color:var(--font-Color)!important;
  }
  /deep/.el-tabs__item{
    color: #737373!important;
  }
.top-box,
.bottom-box {
  padding: 16px 24px;
  background-color: #ffffff;
  border-radius: 4px;
}
// 头部返回标题样式
.return-title{
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  .back-btn{
    font-size: 14px;
    color: #303133;
  }
  .division{
    color: #F3F3F3;
    width: 1px;
    background-color: #F3F3F3;
    border-radius: 2px;
    margin: 0px 16px;
  }
  .back-title{
    font-size: 16px;
    color: #333333;
  }
}
/deep/.title-box {
  margin-bottom: 16px;
}
.name-box {
  display: flex;
  align-items: center;
  .name-box-r {
    display: flex;
    align-items: center;
    margin-left: 12px;
    font-size: 12px;
    color: #666666;
    .line-box {
      display: inline-block;
      margin: 0 12px;
      width: 1px;
      height: 16px;
      background-color: #dcdcdc;
    }
  }
}
.video-box {
  // width: 60%;
  // height: 60vh;
  // max-height: 60vh!important;
  height: 70vh!important;
  display: flex;
  margin-top: 16px;
  .video-box-l{
    padding: 24px;
    // background: linear-gradient(to bottom, #F5FBFF, #ffffff);
    background: #F5FBFF;
    // border: 1px solid;
    // border-image: linear-gradient(to bottom, #E7F4FF, #D1EBFF) 1;
    width: 70%;
    // max-height: 60vh!important;
    overflow-y: scroll;
    position: relative!important;
  }
  .video-box-r {
    width: 30%;
    min-height: 60vh!important;
    max-height: 70vh!important;
    background:#FAFDFF;
    overflow-y: scroll;
    .catalogue-box {
      position: sticky;
      top: 0px;
      left: 0px;
      display: flex;
      align-items: center;
      padding: 12px 24px;
      background: linear-gradient(180deg, #f5fbff 0%, #ffffff 100%);
      img {
        width: 30px;
        height: 30px;
      }
      span {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
      }
    }
    .course-active{
      background-color:#EBF6FF ;
    }
    .catalogue-box-item {
      padding: 7px 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #4d4d4d;
      .catalogue-box-item-l {
        display: flex;
        align-items: center;
        font-size: 14px;
        .icon-icon_course,
        .icon-icon_course_name {
          margin-right: 14px;
          font-size: 20px;
          color: #0089ff;
        }
      }
      .catalogue-box-item-r {
        font-size: 12px;
      }
    }
  }
}
.bottom-box {
  .describe-text {
    margin-top: 8px;
    font-size: 14px;
    color: #4d4d4d;
  }
}
.content {
  background: url("../../../assets/img/data-bg.png");
  padding: 12px 24px;
  height:  calc(100vh - 440px);
  overflow: auto;
  .item-content {
    display: flex;
    align-content: center;
    justify-content: space-between;
    border-bottom: 1px solid #f3f3f3;
    padding: 12px 0;

    &:last-of-type {
      border: none;
    }
    .item-left {
      display: flex;
      align-items: center;
      img {
        width: 38px;
        height: 38px;
        margin-right: 16px;
      }
    }
  }
}
/*滚动条整体部分*/
.mytable-scrollbar ::-webkit-scrollbar {
  width: 1px;
  height: 10px;
}
/*滚动条的轨道*/
.mytable-scrollbar ::-webkit-scrollbar-track {
  background-color: #ffffff;
}
/*滚动条里面的小方块，能向上向下移动*/
.mytable-scrollbar ::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  border-radius: 5px;
  border: 1px solid #f1f1f1;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.mytable-scrollbar ::-webkit-scrollbar-thumb:hover {
  background-color: #a8a8a8;
}
.mytable-scrollbar ::-webkit-scrollbar-thumb:active {
  background-color: #787878;
}
/*边角，即两个滚动条的交汇处*/
.mytable-scrollbar ::-webkit-scrollbar-corner {
  background-color: #ffffff;
}
</style>
