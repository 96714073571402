<!--
 * @Descripttion:
 * @version:
 * @Author: 郭梦霞
 * @Date: 2024-02-07 09:31:02
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-03-04 18:34:28
-->
<template>
<div>
  <div class="" @click="_viewPdf">
    <div class="tools">
      <el-button type="text" @click="FirstPage()">
          <el-tooltip class="item" effect="light" content="第一页" placement="top">
              <i class="el-icon-d-arrow-left"></i>
          </el-tooltip>
      </el-button>
      <el-button type="text" @click="changePdfPage(0)">
          <el-tooltip class="item" effect="light" content="上一页" placement="top">
              <i class="el-icon-arrow-left"></i>
          </el-tooltip>
      </el-button>
      <el-button type="text" @click="changePdfPage(1)">
          <el-tooltip class="item" effect="light" content="下一页" placement="top">
              <i class="el-icon-arrow-right"></i>
          </el-tooltip>
      </el-button>
      <el-button type="text" @click="lastPage()">
          <el-tooltip class="item" effect="light" content="最后一页" placement="top">
              <i class="el-icon-d-arrow-right"></i>
          </el-tooltip>
      </el-button>
      <el-button type="text" @click="setIsExit()" v-show="!isExit" style="margin-right:10px;">
          <el-tooltip class="item" effect="light" content="页码选择" placement="top">
              <i class="el-icon-setting"></i>
          </el-tooltip>
      </el-button>
      <el-tooltip class="item" effect="light" content="页码选择" placement="top" v-show="isExit">
          <el-select v-model="value" placeholder="请选择" @change="pageSelect" size="mini">
              <el-option v-for="item in pageCount" :key="item" :label="'第 '+item+' 页'" :value="item"></el-option>
          </el-select>
      </el-tooltip>
      <el-button type="text" @click="scaleD()">
          <el-tooltip class="item" effect="light" content="放大" placement="top">
              <i class="el-icon-zoom-in"></i>
          </el-tooltip>
      </el-button>
      <el-button type="text" @click="scaleX()">
          <el-tooltip class="item" effect="light" content="缩小" placement="top">
              <i class="el-icon-zoom-out"></i>
          </el-tooltip>
      </el-button>
      <el-button type="text" @click="clock()">
          <el-tooltip class="item" effect="light" content="顺时针旋转" placement="top">
              <i class="el-icon-refresh-right"></i>
          </el-tooltip>
      </el-button>
      <el-button type="text" @click="counterClock()">
          <el-tooltip class="item" effect="light" content="逆时针旋转" placement="top">
              <i class="el-icon-refresh-left"></i>
          </el-tooltip>
      </el-button>
      <el-button type="text" @click="downPDF">
          <el-tooltip class="item" effect="light" content="下载" placement="top">
              <i class="el-icon-download"></i>
          </el-tooltip>
      </el-button>
      <el-button type="text" @click="printPDF">
          <el-tooltip class="item" effect="light" content="打印" placement="top">
              <i class="el-icon-printer"></i>
          </el-tooltip>
      </el-button>
      <p class="total">
          <el-tooltip class="item" effect="light" content="当前页" placement="top">
              <b style="color:#F56C6C;cursor:pointer;">{{ currentPage }}</b> </el-tooltip>&nbsp;/&nbsp;
          <el-tooltip class="item" effect="light" content="总页数" placement="top">
              <b style="color:#67C23A;cursor:pointer;">{{ pageCount }}</b></el-tooltip>
      </p>
    </div>
    <div class="main">
      <!-- <iframe :src="src"  style="width: 100%; height: 100%"></iframe> -->
      <!-- <iframe  :src="`https://view.xdocin.com/view?src=${src}`"
        style="z-index: 1000; height:650px; width: 100%; margin: 0 auto"
        sandbox="allow-scripts allow-top-navigation allow-same-origin allow-popups"
      ></iframe> -->
      <pdf ref="pdf" id="pdf"
        :src="srcUrl"
        style="width:100%;height:100%;"
        :page="currentPage" :rotate="pageRotate" @num-pages="pageCount = $event" @page-loaded="currentPage = $event" @loaded="loadPdfHandler">
      </pdf>
    </div>
  </div>
</div>
</template>

<script>
// 引入
import pdf from 'vue-pdf'
export default {
  name: 'home',
  // 注册
  components: {
    pdf
  },
  props: {
    src: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dialogVisible: false,
      isExit: false,
      value: 1,
      btnText: 'PDF 预览 (vue-pdf 插件)',
      currentPage: 0, // pdf文件页码
      pageCount: 0, // pdf文件总页数
      scale: 100,
      pageRotate: 0,
      srcUrl: ''
    }
  },
  watch: {
    src (newValue, oldValue) {
      this._getSrcUrl()
    }
  },
  created () {
    this._getSrcUrl()
    // this.srcUrl = pdf.createLoadingTask('http://gateway.yingshevip.cn/oss/default/2024/02/24/09/ef01c71517464f1d9b36cd6c3cd02a54.pdf')
    // console.log(this.srcUrl, 'this.srcUrl222')
    // this.srcUrl.promise.then(pdf => {
    //   console.log(pdf, 'jhgg111')
    // })
    // .catch(err => {
    //   console.error('pdf加载失败1', err)
    // })
  },
  mounted () {
    // console.log(this.src)
    // const blob = new Blob(['https://generate.yingshevip.com/default/2024/02/24/15/22b77bec93c349f792b7c862894182a6.pdf'], {
    //   type: 'application/pdf;chartset=UTF-8'
    // })
    // console.log(blob)
    // const tempFile = this.downloadWithFiles('http://gateway.yingshevip.cn/oss/default/2024/02/25/11/ced3e8668c2b48e99e662c4658b656f3.pdf')
    // console.log(tempFile, 'tempFile')
    // this.srcUrl = pdf.createLoadingTask('https://generate.yingshevip.com/oss/default/2024/02/24/15/22b77bec93c349f792b7c862894182a6.pdf', { withCredentials: false })
    // console.log(this.srcUrl, 'this.srcUrl222')
    // this.srcUrl.promise.then(pdf => {
    //   console.log(pdf, 'jhgg222')
    // }).catch(err => {
    //   console.error('pdf加载失败2', err)
    // })
  },
  methods: {
    _getSrcUrl () {
      this.srcUrl = pdf.createLoadingTask(this.src)
      this.$forceUpdate()
    },
    // pdf加载时
    loadPdfHandler () {
      this.value = this.currentPage = 1 // 加载的时候先加载第一页
    },
    // 第一页
    FirstPage () {
      this.value = this.currentPage = 1
      this.isExit = false
    },
    // 最后一页
    lastPage () {
      this.value = this.currentPage = this.pageCount
      this.isExit = false
    },
    // 改变PDF页码,val 传过来区分上一页下一页的值,0 上一页,1 下一页
    changePdfPage (val) {
      if (val === 0 && this.currentPage > 1) {
        this.currentPage--
      }
      if (val === 1 && this.currentPage < this.pageCount) {
        this.currentPage++
      }
      this.value = this.currentPage
      this.isExit = false
    },
    // 页码选择
    pageSelect () {
      this.currentPage = this.value
      this.isExit = false
    },
    // 控制下拉选择框显示隐藏
    setIsExit () {
      this.isExit = true
    },
    // 放大
    scaleD () {
      this.scale += 5
      this.$refs.pdf.$el.style.width = parseInt(this.scale) + '%'
    },
    // 缩小
    scaleX () {
      if (this.scale === 100) {
        return
      }
      this.scale += -5
      this.$refs.pdf.$el.style.width = parseInt(this.scale) + '%'
    },
    // 顺时针
    clock () {
      this.pageRotate += 90
    },
    // 逆时针
    counterClock () {
      this.pageRotate -= 90
    },
    // 下载
    downPDF () {
      var url = this.src
      var tempLink = document.createElement('a')
      tempLink.style.display = 'none'
      tempLink.href = url
      tempLink.setAttribute('download', 'my.pdf')
      console.log(typeof tempLink.download, 11111)
      if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank')
      }
      document.body.appendChild(tempLink)
      tempLink.click()
      document.body.removeChild(tempLink)
    },
    // 打印
    printPDF () {
      this.$refs.pdf.print()
    },
    _viewPdf () {
      // window.open('http://gateway.yingshevip.cn/oss/default/2024/02/24/09/ef01c71517464f1d9b36cd6c3cd02a54.pdf')
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
    margin: 20px 0px;
}

.main {
    border: 2px solid #dcdfe6;
    height: 600px;
    // height:100%;
    overflow: auto;
}

.tools {
    display: flex;
}

.total {
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main::-webkit-scrollbar {
    width: 6px;
}

/* 修改 滚动条的 下面 的 样式 */
.main::-webkit-scrollbar-track {
    background-color: white;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
}

/* 修改 滑块 */
.main::-webkit-scrollbar-thumb {
    background-color: #dcdfe6;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
}

/deep/ .el-dialog {
    height: 735px;
    font-family: '楷体';
}

/deep/ .el-dialog__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #303133;
}

/deep/ .el-dialog__title,
/deep/ .el-dialog__headerbtn .el-dialog__close {
    color: white;
}

/deep/ .el-dialog__body {
    padding: 20px;
}

/deep/ .el-select {
    width: 95px;
    height: 28px;
    margin: 5px 20px 0px 20px;
}
</style>
